import * as React from "react"
import Template from "../components/Template"
import SEO from "../components/SEO"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Alert from "react-bootstrap/Alert"
import axios from 'axios';

const Contacto = (props) => {
    const [form, setForm] = React.useState({
        nombre:"",
        telefono:"",
        correo:"",
        mensaje: ""
    })
    const handleChange=(e)=>{
        const valor=e.target.value
        const campo=e.target.name
        setForm(prevState=>{
            return {
                ...prevState,
                [campo]: valor
            }
        })
    }
    const handleSubmit=(e)=>{
        e.preventDefault()
        document.getElementById("submitContacto").disabled="true"
       axios.post(`/.netlify/functions/send-contact-email`, { 
            message: form.mensaje,
            contactName: form.nombre,
            contactEmail: form.correo,
            contactNumber: form.telefono
        }).then(res=>{
            document.getElementById("submitContacto").removeAttribute('disabled')
            if (typeof window !== 'undefined'){
                window.alert("Mensaje enviado")
            }
        })
    }
  return (
    <Template>
      <SEO title="Contacto"/>
      
          <Container style={{marginTop: "2em"}}>
           <Row className="justify-content-center">
                  <Col lg={6} md={8} sm={10} xs={12}>
                  <h1 style={{textAlign: "center"}}>Contacto</h1>
          <p style={{marginTop: "2em", textAlign: "center"}}>{props.data.sanityGlobalConfig.textContacto}</p>
             
                  <Form className="formContactoAsesor" onSubmit={handleSubmit}>
                    <Form.Group controlId="form.nombre">
                        <Form.Control type="text" name="nombre" value={form.nombre} onChange={handleChange} required placeholder="Su nombre"/>
                    </Form.Group>
                    <Form.Group controlId="form.correoElectronico">
                        <Form.Control type="email" name="correo" value={form.correo} onChange={handleChange} required placeholder="Correo electrónico" />
                    </Form.Group>
                    <Form.Group controlId="form.telefono">
                        <Form.Control type="number" name="telefono" value={form.telefono} onChange={handleChange} required placeholder="Teléfono" />
                    </Form.Group>
                    <Form.Group controlId="form.consulta">
                        <Form.Control as="textarea" name="mensaje" value={form.mensaje} onChange={handleChange} required rows={4} placeholder="Tengo una consulta..." />
                    </Form.Group>
                    <Form.Group controlId="form.submit" style={{textAlign:"center"}}>
                        <Button type="submit" id="submitContacto" variant="primary" style={{backgroundColor:"var(--secondary)", color: "var(--darkText)", border: 0}}>Enviar</Button>
                    </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
                    
    </Template>
  )
}


export default Contacto


export const query = graphql`
  query{
    sanityGlobalConfig {
      textContacto
    }
  }
`
